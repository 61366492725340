// プライバシーポリシー

import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const Privacy = ({ data }) => (
  <>
    <SEO
      pagetitle="プライバシーポリシー"
      pageDesc="このページは、当サイトのプライバシーポリシーのページです。"
    />
    <Layout>
      <div className="privacy">
        <h1>プライバシーポリシー</h1>
        <h2>個人情報の利用目的</h2>
        <p>
          当サイトでは、メールでのお問い合わせ、メールマガジンへの登録などの際に、名前（ハンドルネーム）、メールアドレス等の個人情報をご登録いただく場合がございます。
          これらの個人情報は質問に対する回答や必要な情報を電子メールなどをでご連絡する場合に利用させていただくものであり、個人情報をご提供いただく際の目的以外では利用いたしません。
        </p>
        <h2>個人情報の第三者への開示</h2>
        <p>
          当サイトでは、個人情報は適切に管理し、以下に該当する場合を除いて第三者に開示することはありません。
        </p>
        <p>
          ・本人のご了解がある場合 <br />
          ・法令等への協力のため、開示が必要となる場合
        </p>
        <p>
          個人情報の開示、訂正、追加、削除、利用停止
          ご本人からの個人データの開示、訂正、追加、削除、利用停止のご希望の場合には、ご本人であることを確認させていただいた上、速やかに対応させていただきます。
        </p>
        <h2>アクセス解析ツールについて</h2>
        <p>
          当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。
          このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくはここをクリックしてください。
        </p>
        <h2>広告の配信について</h2>
        <p>
          aikiishinryu.jpは、Amazon.co.jpを宣伝しリンクすることによってサイトが紹介料を獲得できる手段を提供することを目的に設定されたアフィリエイトプログラムである、Amazonアソシエイト・プログラムの参加者です。
          第三者がコンテンツおよび宣伝を提供し、訪問者から直接情報を収集し、訪問者のブラウザにクッキーを設定したりこれを認識したりする場合があります。
        </p>
        <h2>免責事項</h2>
        <p>
          当サイトは、個人情報に関して適用される日本の法令を遵守するとともに、本ポリシーの内容を適宜見直しその改善に努めます。
          修正された最新のプライバシーポリシーは常に本ページにて開示されます。
        </p>
      </div>
    </Layout>
  </>
);

export default Privacy;
